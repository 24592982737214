'use strict';

var core = require('core/components/slider');
var tinySlider = require('tiny-slider/src/tiny-slider');

var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;

// Override core to change tabindex to 0 for accessibility focusing
core.applyTinySlider = function ($container, params) {
    if (!$container) return;

    var tabsContainer = $('.nav-tabs-container');
    if($container.closest(tabsContainer) && $container.closest(tabsContainer).length > 0) {
        $container.closest(tabsContainer).find('.tab-pane').not('.active').addClass('no-active-tab');
    }

    var tslider = tinySlider.tns(params);

    var isBRCarousel = $container.parents().hasClass('bloomreach-widget-recommendations');
    if(isBRCarousel) {
        $container.find('.tns-slider').css('width','');
    }

    if (!!tslider) {
        tslider.events.on('transitionStart', this.slideIntoView);

        this.bindPauseButton($container, tslider);
        this.bindSliderUpdateEvent($container, tslider);
        this.enableImageZoom($container);
        this.handleThumbnailArrows($container, tslider);
        this.handleSliderVideos($container, tslider);

        $('body').trigger('slider:init.sliderEvents', [$container]);
    }

    $container.data('tns', tslider);
    $container.trigger('tooltip:init');

    var controlsEle = $container.find('.tns-controls');
    var carouselTitle = $container.parent().find('.slider-title').text().trim()+'-';
    var prevEle = controlsEle.find('[data-controls="prev"]');
    var nextEle = controlsEle.find('[data-controls="next"]');
    controlsEle.attr('tabindex', -1);
    function setAttributes(ele, btnText) {
        ele.attr({
            'tabindex':  0,
            'aria-label': carouselTitle + controlsEle.attr('aria-label') + btnText
        });
    }
    setAttributes(prevEle, ': Previous');
    setAttributes(nextEle, ': Next');

    tabsContainer.find('.nav-link').on('click', function() {
        var tabPane = $(this).closest(tabsContainer).find('.tab-pane');
        tabPane.addClass('no-active-tab');
        var thisTabpaneID = $(this).attr('href');
        $(thisTabpaneID).removeClass('no-active-tab');
    });
    var heroBannerSlider = $container.has('.handle-image-stacked-js');
    if (heroBannerSlider.length) {
        var firstSlideDisplay = heroBannerSlider.find('.slide:first').css('display');
        heroBannerSlider.find('.slide').not(':first-child').css('display', firstSlideDisplay);
    }
}

// Replaces rvw_autobahn_core
// Build parameter object to create a slider
core.getSliderParams = function($slider, slideItems) {
    var attributes = core.getSliderAttributes($slider);
    var params = {
        container: $slider.find('.slider')[0],
        loop: attributes.loop,
        items: attributes.items,
        autoplay: attributes.autoplay,
        autoplayTimeout: attributes.autoplayTimeout,
        autoplayHoverPause: true,
        mode: attributes.mode,
        gutter: attributes.gutter,
        speed: attributes.speed,
        navContainer: attributes.navContainer,
        navAsThumbnails: attributes.navAsThumbnails,
        controlsContainer: attributes.controlsContainer,
        preventScrollOnTouch: 'auto', // prevents mobile errors in chrome
        swipeAngle: 50,
        responsive: {
            [mediumBreakpoint]: {
                items: attributes.itemsMedium,
                gutter: attributes.gutterMedium,
            },
            [largeBreakpoint]: {
                items: attributes.itemsLarge,
                gutter: attributes.gutterLarge,
            }
        }
    }

    if (slideItems) {
        params.slideItems = slideItems;
    }

    if (params.mode === 'gallery') {
        params.animateIn = attributes.animateIn;
        params.animateOut = attributes.animateOut;
    }

    return params;
}

module.exports = core;